
























import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class SnackBar extends Vue {
  @Prop({ required: true }) snackbar: boolean = false;
  @Prop({ required: true }) text!: string;
  @Prop({ required: true }) timeout!: number;

  color: string = "#000";

  @Emit("close")
  close() {
    return false;
  }
}
